import { Text } from "grommet";
import styled from "styled-components";

const CaretDown = styled.span`
  transform: rotate(90deg);
  margin-right: 2px;
  display: inline-flex;
`;

const CaretUp = styled.span`
  transform: rotate(90deg);
  margin-right: 2px;
  display: inline-flex;
`;

const PriceContainer = styled(Text)`
  display: inline-block;
`;

export default function PriceChange({ value, ...rest }) {
  if (!value)
    return (
      <Text size="xsmall" color="dark-6" {...rest}>
        {value === 0 ? "0%" : "-"}
      </Text>
    );

  if (value < 0)
    return (
      <PriceContainer size="xsmall" color="status-critical" {...rest}>
        <CaretDown>›</CaretDown>
        {value * -1}%
      </PriceContainer>
    );

  return (
    <PriceContainer size="xsmall" color="status-ok" {...rest}>
      <CaretUp>‹</CaretUp>
      {value}%
    </PriceContainer>
  );
}
