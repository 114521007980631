import { useCoin } from "@organisms/coin/provider";
import { Box } from "grommet";
import PriceChange from "components/priceChange";
import DividerCircle from "./dividerCircle";

export default function Price({ slug, ...rest }) {
  const { priceChanges = {}, marketcap } = useCoin(slug);
  const value1 = marketcap ? priceChanges["hour1"] : null;
  const value24 = marketcap ? priceChanges["hour24"] : null;

  return (
    <Box direction="row" gap="xsmall" align="center" justify="evenly">
      <PriceChange value={value1} {...rest} />
      <DividerCircle />
      <PriceChange value={value24} {...rest} />
    </Box>
  );
}
